<template>
  <div>
    <b-button
      block
      variant="primary"
      @click="abrirModal"
    >
      {{ tituloModal() }}
    </b-button>
    <b-modal
      v-model="verModalPreDiagnostico"
      :title="tituloModal()"
      hide-footer
      no-close-on-backdrop
    >
      <validation-observer ref="validarEstados">
        <b-form>
          <b-overlay
            :show="show"
            opacity="0.80"
            variant="transparent"
            blur="0.40rem"
          >
            <template #overlay>
              <div
                style="margin-top: -10vh"
                class="text-center"
              >
                <feather-icon
                  icon="LoaderIcon"
                  size="40"
                  variant="primary"
                  class="rotate"
                />
                <p
                  style="font-weight: bold; font-size: 1.2rem"
                  class="text-primary"
                >
                  GUARDANDO...
                </p>
              </div>
            </template>
            <!-- ESTE ROW SE MUESTRA EN LUMINARIA ESTADO INGRESADA -->
            <b-row v-if="luminaria && luminaria.estado === 'INGRESADA'">
              <b-col
                cols="12"
                sm="12"
              >
                <h5 class="text-primary">
                  Destino de Luminaria
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Destino de Luminaria"
                    rules="required"
                  >
                    <v-select
                      v-model="destinoLuminaria"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="destinosLuminaria"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="destinoLuminaria && destinoLuminaria.id === '2'"
                cols="12"
              >
                <h5 class="text-primary">
                  Listado de Pre diagnósticos
                </h5>
                <b-table
                  v-model="fallasSeleccionadas"
                  :items="fallas"
                  :fields="camposTabla"
                >
                  <template v-slot:cell(nombre)="{ item }">
                    <h5 class="align-text-top text-uppercase">
                      {{ item.nombre }}
                    </h5>
                  </template>
                  <template v-slot:cell(check)="{ item }">
                    <b-form-checkbox
                      v-model="item.fallasSeleccionadas"
                    />
                  </template>
                </b-table>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="de observaciones"
                    rules="required"
                  >
                    <h6 class="text-primary">
                      {{ destinoLuminaria.id === '3' ? 'Justificación para realizar el Descarte' : 'Observaciones del Pre-Diagnostico' }}
                    </h6>
                    <b-form-textarea
                      v-model="diagnostico.observaciones"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ESTE ROW SE MUESTRA SI ESTA EN REPARACIÓN APVN-->
            <b-row v-if="luminaria && luminaria.estado === 'REPARACIÓN'">
              <b-col cols="12">
                <center>
                  <h3
                    class="text-primary"
                    style="font-weight: bold; margin-top: -5px"
                  >
                    REPARACIONES
                  </h3>
                </center>
                <hr style="margin-top: -5px">
              </b-col>

              <b-col
                cols="12"
                sm="12"
              >
                <h5 class="text-primary">
                  Lista de Reparaciones
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Reparaciones de Luminaria"
                    rules="required"
                  >
                    <v-select
                      v-model="reparacionesLuminaria"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      multiple
                      :options="reparaciones"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Observaciones de las Reparaciones"
                    rules="required"
                  >
                    <h6 class="text-primary">
                      Observaciones de las Reparaciones
                    </h6>
                    <b-form-textarea
                      v-model="diagnostico.observaciones"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-col cols="12">
                  <center>
                    <h3
                      class="text-primary"
                      style="font-weight: bold; margin-top: -5px"
                    >
                      PRUEBAS
                    </h3>
                  </center>
                  <hr style="margin-top: -5px">
                </b-col>
                <h5 class="text-primary">
                  Listado de Pruebas de Luminaria
                </h5>
                <b-table
                  v-model="pruebasSeleccionadas"
                  :items="pruebasLamparas"
                  :fields="camposTabla"
                >
                  <template v-slot:cell(nombre)="{ item }">
                    <h5 class="align-text-top text-uppercase">
                      {{ item.nombre }}
                    </h5>
                  </template>
                  <template v-slot:cell(check)="{ item }">
                    <b-form-checkbox
                      v-model="item.pruebasSeleccionadas"
                    />
                  </template>
                </b-table>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Observaciones de las Pruebas"
                    rules="required"
                  >
                    <h6 class="text-primary">
                      Observaciones de las Pruebas
                    </h6>
                    <b-form-textarea
                      v-model="observacionesPruebas"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ESTE ROW SE MUESTRA SI ESTA EN GARANTÍA -->
            <b-row v-if="pruebasValidas(luminaria, 'GARANTÍA', '2')">
              <b-col
                cols="12"
                sm="12"
              >
                <h5 class="text-primary">
                  ¿Pruebas Exitosas o Fallidas?
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Pruebas de Luminaria"
                    rules="required"
                  >
                    <v-select
                      v-model="pruebasLuminaria"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="pruebasLuminarias"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="pruebasLuminaria.id === '1'"
                cols="12"
                sm="12"
              >
                <h5 class="text-primary">
                  Listado de Pruebas de Luminaria
                </h5>
                <b-table
                  v-model="pruebasSeleccionadas"
                  :items="pruebasLamparas"
                  :fields="camposTabla"
                >
                  <template v-slot:cell(nombre)="{ item }">
                    <h5 class="align-text-top text-uppercase">
                      {{ item.nombre }}
                    </h5>
                  </template>
                  <template v-slot:cell(check)="{ item }">
                    <b-form-checkbox
                      v-model="item.pruebasSeleccionadas"
                    />
                  </template>
                </b-table>
              </b-col>
              <b-col cols="12">
                <h5 class="text-primary">
                  Confirmación de Serie
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Confirmación de Serie"
                    rules="required"
                  >
                    <b-form-input
                      v-model="serieConfirmada"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Observaciones de la Reparación de Garantía"
                    rules="required"
                  >
                    <h6 class="text-primary">
                      Observaciones de la Reparación de Garantía
                    </h6>
                    <b-form-textarea
                      v-model="diagnostico.observaciones"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ESTE ROW SE MUESTRA EN ESTADO REPARADA POR LABORATORIOS APVN -->
            <b-row v-if="reparadaApvn(luminaria)">
              <b-col
                cols="12"
              >
                <h6 class="text-primary">
                  Grupo a Despachar
                </h6>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Grupo a Despachar"
                    rules="required"
                  >
                    <v-select
                      v-model="grupoAsignado"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="grupos"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="mostrarModelo"
                cols="12"
                sm="12"
              >
                <h5 class="text-primary">
                  Modelo de Lámpara
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Modelo de Lámpara"
                    rules="required"
                  >
                    <v-select
                      v-model="modelo"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="modelosLuminaria"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Observaciones del Despacho"
                    rules="required"
                  >
                    <h6 class="text-primary">
                      Observaciones del Despacho
                    </h6>
                    <b-form-textarea
                      v-model="diagnostico.observaciones"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ESTE ROW SE MUESTRA EN ESTADO DESPACHO Y MÓDULO LUMINARIAS -->
            <b-row v-if="luminaria.estado === 'DESPACHO' && luminaria.seguimiento[0].destinoLuminaria.id === '1'">
              <b-col
                cols="12"
              >
                <h6 class="text-primary">
                  Grupo a Despachar
                </h6>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Grupo a Despachar"
                    rules="required"
                  >
                    <v-select
                      v-model="grupoAsignado"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="grupos"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="mostrarModelo"
                cols="12"
                sm="12"
              >
                <h5 class="text-primary">
                  Modelo de Lámpara
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Modelo de Lámpara"
                    rules="required"
                  >
                    <v-select
                      v-model="modelo"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="modelosLuminaria"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Observaciones del Despacho"
                    rules="required"
                  >
                    <h6 class="text-primary">
                      Observaciones del Despacho
                    </h6>
                    <b-form-textarea
                      v-model="diagnostico.observaciones"
                      :state="errors.length > 0 ? false:null"
                      class="qr-textarea"
                      rows="2"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ESTE ROW SE MUESTRA SI FUE REPARADA EN GARANTÍA -->
            <b-row v-if="reparadaGarantia(luminaria)">
              <b-col
                cols="12"
              >
                <h5 class="text-primary">
                  Grupo a Despachar
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Grupo a Despachar"
                    rules="required"
                  >
                    <v-select
                      v-model="grupoAsignado"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="grupos"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="mostrarModelo"
                cols="12"
                sm="12"
              >
                <h5 class="text-primary">
                  Modelo de Lámpara
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Modelo de Lámpara"
                    rules="required"
                  >
                    <v-select
                      v-model="modelo"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="modelosLuminaria"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="de observaciones"
                    rules="required"
                  >
                    <h6 class="text-primary">
                      Observaciones para el Despacho
                    </h6>
                    <b-form-textarea
                      v-model="diagnostico.observaciones"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ESTE ROW ES PARA LAS LUMINARIAS ANTERIORES QUE SE ENCUENTRAN EN ESTOS ESTADOS -->
            <b-row v-if="luminaria.estado === 'MANTENIMIENTO DE LUMINARIA'">
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="de observaciones"
                    rules="required"
                  >
                    <h6 class="text-primary">
                      Observaciones para el Despacho
                    </h6>
                    <b-form-textarea
                      v-model="diagnostico.observaciones"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="luminaria.estado === 'LUMINARIA PARA DESPACHO'">
              <b-col
                cols="12"
              >
                <h5 class="text-primary">
                  Grupo a Despachar
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Grupo a Despachar"
                    rules="required"
                  >
                    <v-select
                      v-model="grupoAsignado"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="grupos"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="mostrarModelo"
                cols="12"
                sm="12"
              >
                <h5 class="text-primary">
                  Modelo de Lámpara
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Modelo de Lámpara"
                    rules="required"
                  >
                    <v-select
                      v-model="modelo"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="modelosLuminaria"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="de observaciones"
                    rules="required"
                  >
                    <h6 class="text-primary">
                      Observaciones para el Despacho
                    </h6>
                    <b-form-textarea
                      v-model="diagnostico.observaciones"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ESTE ROW SE MOSTRARÁ SI FUE A PINTURA -->
            <b-row v-if="luminaria.estado === 'PINTURA'">
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="de observaciones"
                    rules="required"
                  >
                    <h6 class="text-primary">
                      Observaciones de la luminaria enviada a Pintura
                    </h6>
                    <b-form-textarea
                      v-model="diagnostico.observaciones"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ESTE ROW SIEMPRE SE MOSTRARÁ YA QUE ES LA FOTOGRAFÍA (OPCIONAL) -->
            <b-row>
              <b-col
                style="display: flex; justify-content: end"
                cols="12"
              >
                <b-button
                  block
                  variant="primary"
                  @click="agregarFoto($event)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Agregar Foto de Luminaria</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <table>
                    <tr
                      v-for="(foto, index) in fotosTracking"
                      :key="index"
                    >
                      <td style="width: 70%">
                        <Imagen
                          :key="'recepcion-' + index"
                          :leyenda="'recepcion-' + index"
                          @cargar-file="imagenTracking(index, $event)"
                        />
                      </td>
                      <td>
                        <b-button
                          variant="danger"
                          @click="eliminarFoto()"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Eliminar</span>
                        </b-button>
                      </td>
                    </tr>
                  </table>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col
                style="display: flex; justify-content: end"
                cols="12"
              >
                <b-button
                  variant="primary"
                  @click="validationForm"
                >
                  Guardar Diagnóstico
                </b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </b-form>
      </validation-observer>
    </b-modal>
    <div>
      <b-modal
        id="modalDestino"
        v-model="verModalDestino"
        title="Validación de diagnostico"
        hide-footer
        centered
        no-close-on-backdrop
      >
        <validation-observer ref="validarDestino">
          <b-form>
            <b-overlay
              :show="show"
              opacity="0.70"
              variant="transparent"
              blur="1rem"
            >
              <b-row>
                <b-col cols="6">
                  <b-button
                    block
                    size="lg"
                    variant="primary"
                    @click="enviarAPintura"
                  >
                    REQUIERE PINTURA
                    <br>
                    <Icon
                      :style="{fontSize: '36'}"
                      icon="mingcute:paint-fill"
                    />
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button
                    block
                    size="lg"
                    variant="success"
                    @click="enviarAReparacion"
                  >
                    QUEDA REPARADA
                    <br>
                    <Icon
                      :style="{fontSize: '36'}"
                      icon="hugeicons:setting-done-01"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <!--b-row>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <center>
                    <h3 class="text-primary">
                      Validación de reparación
                    </h3>
                  </center>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Validación de reparación"
                      rules="required"
                    >
                      <v-select
                        v-model="reparadaPintura"
                        :state="errors.length > 0 ? false:null"
                        class="text-center"
                        label="nombre"
                        :options="opcionesPinturaDespacho"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr style="margin-top: -5px">
              <b-row>
                <b-col cols="12">
                  <b-button
                    block
                    variant="gradient-primary"
                    @click="validarPinturaReparada"
                  >
                    Guardar Diagnostico
                  </b-button>
                </b-col>
              </b-row-->
            </b-overlay>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BButton, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormTextarea, BOverlay, BRow, BTable,
} from 'bootstrap-vue'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  updateCreateLuminaria,
  getListadoFallasLuminaria,
  creacionLuminaria, descartarLuminaria,
} from '@/utils/mantenimiento/bodega/bodegaUtils'
import { required } from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import vSelect from 'vue-select'
import { saveImagen } from '@/utils/imagenes'
import { getlistado } from '@/utils/catalogos'
import { findLuminariasLab, updateCreateLuminariaLab } from '@/utils/laboratorio/luminarias'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/vue2'

export default {
  components: {
    Icon,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Imagen,
    BCol,
    BRow,
    BForm,
    BTable,
    BButton,
    BOverlay,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  props: {
    luminaria: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      modelo: '',
      verModalDestino: false,
      verModalConfirmacion: false,
      mostrarModelo: false,
      show: false,
      showOverlay: false,
      verModalPreDiagnostico: false,
      diagnostico: {
        preDiagnostico: [],
        observaciones: null,
        usuario: JSON.parse(localStorage.getItem('userData')),
      },
      observacionesPruebas: '',
      serieConfirmada: null,
      reparacionesLuminaria: '',
      reparadaPintura: '',
      grupoAsignado: '',
      destinoLuminaria: '',
      pruebasLuminaria: '',
      pruebas: '',
      fotosTracking: [''],
      grupos: [],
      fallasSeleccionadas: [],
      pruebasSeleccionadas: [],
      fallas: [],
      fallasLamparas: [],
      destinosLuminaria: [],
      pruebasLamparas: [],
      pruebasLuminarias: [],
      reparaciones: [],
      opcionesPinturaDespacho: [],
      modelosLuminaria: [],
      luminariaEncontrada: [],
      camposTabla: [
        { key: 'nombre', label: 'Nombre' },
        { key: 'check', label: '' },
      ],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  watch: {
    destinoLuminaria(newVal, oldVal) {
      if (oldVal && oldVal.id === '1' && newVal.id !== '1') {
        this.limpiarFallasSeleccionadas()
      }
    },
    pruebasLuminaria(newVal) {
      if (newVal && newVal.id === '2') {
        this.limpiarGrupoSeleccionado()
      }
    },
  },
  async created() {
    this.serieConfirmada = this.luminaria.codigo
    this.fallasLamparas = await getlistado(22, 'Fallas Lámparas Bodega', false, this.usuario)
    this.fallasLamparas.forEach(l => {
      // eslint-disable-next-line no-param-reassign
      l.selectedValue = false
    })
    this.pruebasLamparas = await getlistado(23, 'Pruebas Lámparas Bodega', false, this.usuario)
    this.pruebasLamparas.forEach(p => {
      // eslint-disable-next-line no-param-reassign
      p.selectedValue = false
    })
    this.reparaciones = await getlistado(24, 'Reparaciones Laboratorio', false, this.usuario)
    this.grupos = await getlistado(12, 'Grupos', false, this.usuario)
    this.modelosLuminaria = await getlistado(9, 'Tipo de Lámparas', false, this.usuario)
    this.destinosLuminaria = await getListadoFallasLuminaria(1)
    this.pruebasLuminarias = await getListadoFallasLuminaria(5)
    this.opcionesPinturaDespacho = await getListadoFallasLuminaria(8)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
    await this.cargarFallas()
  },
  methods: {
    enviarAPintura() {
      // eslint-disable-next-line prefer-destructuring
      this.reparadaPintura = this.opcionesPinturaDespacho[0] // El objeto 0 tiene id "1" es que requiere pintura
      this.validarPinturaReparada()
    },
    enviarAReparacion() {
      // eslint-disable-next-line prefer-destructuring
      this.reparadaPintura = this.opcionesPinturaDespacho[1] // El objeto 1 tiene id "2" es que no requiere pintura
      this.validarPinturaReparada()
    },
    validarPinturaReparada() {
      this.$refs.validarDestino.validate()
        .then(success => {
          if (success) {
            this.guardarPreDiagnostico()
          }
        })
    },
    limpiarFallasSeleccionadas() {
      this.fallasSeleccionadas = []
      this.fallas.forEach(falla => {
        // eslint-disable-next-line no-param-reassign
        falla.fallasSeleccionadas = false
      })
    },
    limpiarGrupoSeleccionado() {
      if (this.pruebasLuminaria.id === '2') {
        this.grupoAsignado = ''
      }
    },
    pruebasValidas(luminaria, estado, idDestino) {
      if (!luminaria || luminaria.estado !== estado || !Array.isArray(luminaria.seguimiento)) {
        return false
      }

      const seguimientoCopia = [...luminaria.seguimiento]
      const ultimoSeguimiento = seguimientoCopia.reverse().find(seg => seg.destinoLuminaria && seg.destinoLuminaria.id === idDestino)

      return ultimoSeguimiento !== undefined
    },
    reparadaApvn(luminaria) {
      if (!luminaria || luminaria.estado !== 'REPARADA') {
        return false
      }
      if (!Array.isArray(luminaria.seguimiento) || luminaria.seguimiento.length < 2) {
        return false
      }
      return !(!luminaria.seguimiento[1].destinoLuminaria || luminaria.seguimiento[1].destinoLuminaria.id !== '1')
    },
    reparadaGarantia(luminaria) {
      if (!luminaria || luminaria.estado !== 'REPARADA') {
        return false
      }
      if (!Array.isArray(luminaria.seguimiento) || luminaria.seguimiento.length < 2) {
        return false
      }
      return !(!luminaria.seguimiento[1].destinoLuminaria || luminaria.seguimiento[1].destinoLuminaria.id !== '2')
    },
    validationForm() {
      this.$refs.validarEstados.validate()
        .then(success => {
          if (success) {
            if (this.luminaria.estado === 'INGRESADA' && this.destinoLuminaria.id === '3') {
              const hasPhoto = this.fotosTracking.filter(foto => foto !== '').length > 0
              if (!hasPhoto) {
                mensajeError('Debe Ingresar una Fotografía para Descartar la Luminaria!')
                return
              }
              this.guardarPreDiagnostico()
              return
            }
            if (this.luminaria.estado === 'REPARACIÓN') {
              const hasPhoto = this.fotosTracking.filter(foto => foto !== '').length > 0
              if (!hasPhoto) {
                mensajeError('Este diagnostico requiere una fotografía!')
                return
              }
              this.verModalDestino = true
              return
            }
            if (this.luminaria.estado === 'GARANTÍA' && this.pruebasLuminaria.id === '1') {
              const hasPhoto = this.fotosTracking.filter(foto => foto !== '').length > 0
              if (!hasPhoto) {
                mensajeError('Este diagnostico requiere una fotografía!')
                return
              }
              this.verModalDestino = true
              return
            }
            if (this.luminaria.estado === 'INGRESADA') {
              this.guardarPreDiagnostico()
              return
            }
            if (this.luminaria.estado === 'GARANTÍA' && this.pruebasLuminaria.id === '2') {
              this.guardarPreDiagnostico()
              return
            }
            if (this.luminaria.estado === 'PINTURA') {
              const hasPhoto = this.fotosTracking.filter(foto => foto !== '').length > 0
              if (!hasPhoto) {
                mensajeError('Se requiere una fotografía de la luminaria!')
                return
              }
              this.guardarPreDiagnostico()
              return
            }
            if (this.luminaria.estado === 'DESPACHO') {
              this.guardarPreDiagnostico()
            }
          }
        })
    },
    async guardarPreDiagnostico() {
      this.show = true
      try {
        this.diagnostico.usuario = {
          id: this.usuario.id,
          nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
          email: this.usuario.email,
          telefono: '',
        }

        const body = { ...this.luminaria }
        const urlBucket = `falla-lampara/${body.id}`
        const isMobile = window.screen.width <= 760

        // eslint-disable-next-line no-unused-vars
        let estado = ''
        const ultimoId = Math.max(...body.seguimiento.map(seg => seg.id), 0)

        if (this.luminaria.estado === 'INGRESADA') {
          if (this.destinoLuminaria.id === '1') {
            estado = 'Pre diagnóstico'
            body.estado = 'REPARACIÓN'
            body.tracking.push({
              estado: 'REPARACIÓN',
              horaFecha: new Date(),
              usuario: this.diagnostico.usuario,
            })
            body.preDiagnosticos = this.fallasSeleccionadas
          } else if (this.destinoLuminaria.id === '2') {
            estado = 'Pre diagnóstico'
            body.estado = 'GARANTÍA'
            body.tracking.push({
              estado: 'GARANTÍA',
              horaFecha: new Date(),
              usuario: this.diagnostico.usuario,
            })
            body.preDiagnosticos = this.fallasSeleccionadas
          } else if (this.destinoLuminaria.id === '3') {
            estado = 'Pre diagnóstico'
            body.estado = 'DESCARTADA'
            body.tracking.push({
              estado: 'DESCARTADA',
              horaFecha: new Date(),
              usuario: this.diagnostico.usuario,
            })
          }
        } else if (this.luminaria.estado === 'REPARACIÓN') {
          if (this.reparadaPintura.id === '1') {
            estado = 'Luminaria Requería Pintura'
            body.estado = 'PINTURA'
            body.tracking.push({
              estado: 'PINTURA',
              horaFecha: new Date(),
              usuario: this.diagnostico.usuario,
            })
          } else if (this.reparadaPintura.id === '2') {
            estado = 'Luminaria Reparada'
            body.estado = 'REPARADA'
            body.tracking.push({
              estado: 'REPARADA',
              horaFecha: new Date(),
              usuario: this.diagnostico.usuario,
            })
          }
          body.reparaciones = this.reparacionesLuminaria
          body.pruebas = this.pruebasSeleccionadas
        } else if (this.luminaria.estado === 'GARANTÍA') {
          if (this.reparadaPintura.id === '1') {
            estado = 'Luminaria Requería Pintura'
            body.estado = 'PINTURA'
            body.tracking.push({
              estado: 'PINTURA',
              horaFecha: new Date(),
              usuario: this.diagnostico.usuario,
            })
          } else if (this.reparadaPintura.id === '2') {
            const primerSeguimiento = this.luminaria.seguimiento[1]
            if (primerSeguimiento) {
              if (primerSeguimiento.destinoLuminaria.id === '2' && this.pruebasLuminaria) {
                if (this.pruebasLuminaria.id === '1') {
                  body.pruebas = this.pruebasSeleccionadas
                  body.estado = 'REPARADA'
                  estado = 'Luminaria Reparada'
                }
              }
            }
          }
          body.codigo = this.serieConfirmada
        } else if (this.luminaria.estado === 'REPARADA') {
          const primerSeguimiento = this.luminaria.seguimiento[1]
          if (primerSeguimiento) {
            if (primerSeguimiento.destinoLuminaria.id === '1') {
              body.estado = 'LUMINARIA DESPACHADA'
              body.grupo = this.grupoAsignado
            } else if (primerSeguimiento.destinoLuminaria.id === '2') {
              body.estado = 'LUMINARIA DESPACHADA'
              body.grupo = this.grupoAsignado
            }
            estado = 'Despacho de Luminaria'
            body.tracking.push({
              estado: body.estado,
              horaFecha: new Date(),
              usuario: this.diagnostico.usuario,
            })
          }
        } else if (this.luminaria.estado === 'DESPACHO') {
          const primerSeguimiento = this.luminaria.seguimiento[0]
          if (primerSeguimiento) {
            if (primerSeguimiento.destinoLuminaria.id === '1') {
              body.estado = 'DESPACHADA'
              body.grupo = this.grupoAsignado
            } else if (primerSeguimiento.destinoLuminaria.id === '2') {
              body.estado = 'DESPACHADA'
              body.grupo = this.grupoAsignado
            }
            estado = 'Luminaria Despachada'
            body.tracking.push({
              estado: body.estado,
              horaFecha: new Date(),
              usuario: this.diagnostico.usuario,
            })
          }
        } else if (this.luminaria.estado === 'MANTENIMIENTO DE LUMINARIA') {
          estado = 'Luminaria para Despacho'
          body.estado = 'LUMINARIA PARA DESPACHO'
          body.tracking.push({
            estado: 'LUMINARIA PARA DESPACHO',
            horaFecha: new Date(),
            usuario: this.diagnostico.usuario,
          })
        } else if (this.luminaria.estado === 'LUMINARIA PARA DESPACHO') {
          estado = 'Luminaria Despachada'
          body.estado = 'LUMINARIA DESPACHADA'
          body.grupo = this.grupoAsignado
          body.tracking.push({
            estado: 'LUMINARIA DESPACHADA',
            horaFecha: new Date(),
            usuario: this.diagnostico.usuario,
          })
        } else if (this.luminaria.estado === 'PINTURA') {
          estado = 'Luminaria con pintura'
          body.estado = 'REPARADA'
          body.tracking.push({
            estado: 'REPARADA',
            horaFecha: new Date(),
            usuario: this.diagnostico.usuario,
          })
        }
        let nuevoSeguimiento
        if (this.luminaria.estado !== 'DESPACHO') {
          nuevoSeguimiento = {
            // eslint-disable-next-line no-plusplus
            id: ultimoId + 1,
            estado,
            destinoLuminaria: this.destinoLuminaria,
            observaciones: this.diagnostico.observaciones,
            observacionesPruebas: this.observacionesPruebas,
            pruebasLuminaria: this.pruebasLuminaria,
            fechaHora: new Date(),
            tracking: [],
            usuario: this.diagnostico.usuario,
          }
        } else {
          nuevoSeguimiento = {
            estado,
            fecha: new Date(),
            grupo: this.grupoAsignado,
            fotos: [],
            observaciones: this.diagnostico.observaciones,
            usuario: this.diagnostico.usuario,
          }
        }

        if (this.luminaria.estado !== 'LUMINARIA PARA DESPACHO') {
          // eslint-disable-next-line no-restricted-syntax
          for (const foto of this.fotosTracking) {
            if (foto !== '') {
              // eslint-disable-next-line no-shadow,no-await-in-loop
              const imagen = await saveImagen(urlBucket, this.localization, foto, estado, '', this.diagnostico.usuario, isMobile)
              if (imagen && this.luminaria.estado !== 'DESPACHO') {
                nuevoSeguimiento.tracking.push({
                  ...imagen,
                })
              } else if (imagen && this.luminaria.estado === 'DESPACHO') {
                nuevoSeguimiento.fotos.push({ ...imagen })
              }
            }
          }
        }
        body.seguimiento.push(nuevoSeguimiento)
        if (this.luminaria.estado === 'LUMINARIA PARA DESPACHO') {
          const resultado = await creacionLuminaria(this.luminaria.id, this.luminaria, this.usuario, 'REPARADA', this.modelo, this.pruebasSeleccionadas, this.reparaciones)
          if (!resultado) {
            mensajeError('Algo ha salido mal, intenta nuevamente!')
            return
          }
        }
        if (this.luminaria.estado === 'REPARACIÓN' && this.reparadaPintura.id === '2') {
          const resultado = await creacionLuminaria(this.luminaria.id, this.luminaria, this.usuario, 'REPARADA', this.modelo, this.pruebasSeleccionadas, this.reparaciones)
          if (!resultado) {
            mensajeError('Algo ha salido mal, intenta nuevamente!')
            return
          }
        }
        if (this.luminaria.estado === 'GARANTÍA' && this.reparadaPintura.id === '2') {
          const resultado = await creacionLuminaria(this.luminaria.id, this.luminaria, this.usuario, 'REPARADA', this.modelo, this.pruebasSeleccionadas, this.reparaciones)
          if (!resultado) {
            mensajeError('Algo ha salido mal, intenta nuevamente!')
            return
          }
        }
        if (this.luminaria.estado === 'PINTURA') {
          const resultado = await creacionLuminaria(this.luminaria.id, this.luminaria, this.usuario, 'REPARADA', this.modelo, this.pruebasSeleccionadas, this.reparaciones)
          if (!resultado) {
            mensajeError('Algo ha salido mal, intenta nuevamente!')
            return
          }
        }
        if (this.luminaria.estado === 'INGRESADA' && this.destinoLuminaria.id === '3') {
          await descartarLuminaria(this.luminaria, this.usuario, nuevoSeguimiento, this.diagnostico.observaciones)
        }
        if (this.luminaria.estado === 'DESPACHO' && this.usuario.company.name === 'APVN') {
          await updateCreateLuminariaLab(body, 1)
        } else {
          await updateCreateLuminaria(body, 1)
        }

        mensajeInformativo('Guardada', 'Diagnóstico de Luminaria Almacenada Correctamente!')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Diagnóstico de Luminaria Almacenada!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Diagnóstico de Luminaria correctamente!',
          },
        })
        this.show = false
        this.$emit('cerrar-detalle')
        this.verModalPreDiagnostico = false
      } catch (error) {
        console.error(error)
        mensajeError('Algo ha salido mal, intenta nuevamente!')
      } finally {
        this.show = false
        this.verModalDestino = false
      }
    },
    async abrirModal() {
      this.verModalPreDiagnostico = true
      if (this.luminaria.estado === 'REPARADA' || this.luminaria.estado === 'LUMINARIA PARA DESPACHO') {
        let luminariaEncontrada = null
        if (this.luminaria.idLuminaria) {
          const filter = {
            where: {
              companyId: this.usuario.company.id,
              id: this.luminaria.idLuminaria,
            },
          }
          luminariaEncontrada = await findLuminariasLab(filter)
        }

        if (!luminariaEncontrada || luminariaEncontrada.length === 0) {
          const filter = {
            where: {
              companyId: this.usuario.company.id,
              serie: this.luminaria.codigo,
            },
          }
          luminariaEncontrada = await findLuminariasLab(filter)
        }
        if (luminariaEncontrada.length > 0) {
          const luminaria = luminariaEncontrada[0]
          if (luminaria.tipoLampara) {
            this.mostrarModelo = false
          }
        } else {
          // Maneja el caso en el que no se encuentra ninguna luminaria
          this.mostrarModelo = true
        }
      } else if (this.luminaria.estado === 'INGRESADA' || this.luminaria.estado === 'GARANTÍA') {
        const filter = {
          where: {
            companyId: this.usuario.company.id,
            serie: this.luminaria.codigo,
          },
        }
        this.luminariaEncontrada = await findLuminariasLab(filter)
        if (this.luminariaEncontrada.length > 0) {
          const luminaria = this.luminariaEncontrada[0]
          if (luminaria.id) {
            this.luminaria.idLuminaria = luminaria.id
          }
        }
      }
    },
    // eslint-disable-next-line consistent-return
    tituloModal() {
      if (this.luminaria && this.luminaria.estado === 'INGRESADA') {
        return 'Pre-Diagnostico de Luminaria'
        // eslint-disable-next-line no-mixed-operators
      } if (this.luminaria && this.luminaria.estado === 'REPARACIÓN') {
        return 'Reparación de Laboratorio'
      } if (this.luminaria && this.luminaria.estado === 'GARANTÍA') {
        return 'Reparación de Garantía'
      } if (this.luminaria && this.luminaria.estado === 'REPARADA') {
        return 'Despachar Luminaria'
      } if (this.luminaria && this.luminaria.estado === 'MANTENIMIENTO DE LUMINARIA') {
        return 'Luminaria para Despacho'
      } if (this.luminaria && this.luminaria.estado === 'LUMINARIA PARA DESPACHO') {
        return 'Despacho de Luminaria'
      } if (this.luminaria && this.luminaria.estado === 'PINTURA') {
        return 'Pintura de Luminaria'
      } if (this.luminaria && this.luminaria.estado === 'DESPACHO') {
        return 'Despachar Luminaria'
      }
    },
    agregarFoto() {
      this.fotosTracking.push('')
    },
    eliminarFoto(index) {
      if (this.fotosTracking.length > 1) {
        this.fotosTracking.splice(index, 1)
      }
    },
    imagenTracking(index, file) {
      this.$set(this.fotosTracking, index, file)
    },
    async cargarFallas() {
      try {
        this.fallas = await getlistado(22, 'Pruebas Lámparas Bodega', false, this.usuario)
      } catch (error) {
        console.error('Error al obtener la lista de fallas:', error)
      }
    },
  },
}
</script>
<style scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1.5s linear infinite;
}
</style>
