<template>
  <div>
    <b-row style="justify-content: center; display: flex">
      <b-card class="custom-card">
        <b-row>
          <b-col cols="6">
            <b-col cols="12">
              <b-img
                :src="codigoQRDataURL"
                class="imagenQR"
                alt="Código QR"
              />
            </b-col>
          </b-col>
          <b-col
            style="margin-left: -30px; margin-top: 2px"
            cols="6"
          >
            <b-col cols="12">
              <br>
              <div style="display: flex; justify-content: center">
                <h1 class="titulo-serie">
                  {{ luminaria.serie }}
                </h1>
              </div>
            </b-col>
            <b-col cols="12">
              <h4 class="titulo-propiedad">
                PROPIEDAD DE:
              </h4>
              <div style="display: flex; justify-content: center">
                <b-img
                  :src="logo"
                  style="width: 250px; margin-top: -10px"
                  alt="Imagen"
                />
              </div>
            </b-col>
          </b-col>
        </b-row>
      </b-card>
    </b-row>
    <hr>
    <b-row>
      <b-col
        style="display: flex; justify-content: end"
        cols="12"
      >
        <b-button
          variant="primary"
          @click="imprimirEtiqueta"
        >
          Imprimir Etiqueta
          <feather-icon
            icon="PrinterIcon"
            class="ml-50"
            size="18"
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BImg, BButton,
} from 'bootstrap-vue'
import QRCode from 'qrcode-generator'
// eslint-disable-next-line import/no-absolute-path,import/no-unresolved
import logo from '/public/logo-negro-puntos.png'
import { imprimirEtiquetaZebra } from '@/components/mantenimiento/bodega/impresora'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BButton,
  },
  props: {
    luminaria: {
      type: Object,
    },
  },
  data() {
    return {
      codigoQRDataURL: '',
      logo,
    }
  },
  mounted() {
    // El código es la serie de la luminaria
    const qr = QRCode(0, 'M')
    const jsonData = {
      serie: this.luminaria.serie,
    }

    const jsonString = JSON.stringify(jsonData)
    const base64Data = btoa(jsonString)
    qr.addData(base64Data)
    qr.make()
    qr.createImgTag(10, 0, '#a3a3a3', '#a3a3a3', true)
    this.codigoQRDataURL = qr.createDataURL(10, 0)
  },
  methods: {
    imprimirEtiqueta() {
      const { serie } = this.luminaria // Puedes obtener esto de tu luminaria
      // eslint-disable-next-line no-shadow
      const logoApvn = this.logo
      const qrEtiqueta = this.codigoQRDataURL

      // Llama a la función imprimirEtiquetaZebra con los nuevos parámetros
      imprimirEtiquetaZebra(serie, logoApvn, qrEtiqueta)
    },
  },
}
</script>
<style scoped>
/* Aplica la clase "shadow" y ajusta la elevación según tus necesidades */
.custom-card {
  margin-top: 15px;
  justify-self: center;
  display: flex;
  border-radius: 20px;
  width: 70%;
  box-shadow:  20px 20px 60px #868686, -20px -20px 60px #ffffff;
}
.titulo-serie {
  color: #1e1e1e;
  font-weight: bold;
  font-size: 30px;
}
.titulo-propiedad {
  color: #1e1e1e;
  font-weight: bold;
  font-size: 25px;
  display: flex;
  justify-content: center;
}
.imagenQR {
  background-color: #a3a3a3;
  width: 200px;
  margin-top: 10px;
  margin-right: -25px;
  margin-left: 5px;
  border-radius: 8px;
}
</style>
