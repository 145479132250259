<template>
  <div>
    <files
      :origen="`postes/${this.poste.id}`"
      :title="'Agregar Nuevo Archivo'"
      :type="'files'"
      :files="poste.files"
      @update-origen="updateFiles"
    />
  </div>
</template>
<script>
import Files from '@/components/Files.vue'

export default {
  components: {
    Files,
  },
  props: {
    luminaria: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      poste: {
        id: '',
        files: [],
      },
    }
  },
  methods: {
    async updateFiles(data) {
      if (!this.poste.files) this.poste.files = []

      if (data.opcion === 'ADD') {
        this.poste.files.push(data.file)
      } else {
        this.poste.files.splice(data.index, 1)
      }
      this.updateItem(false)
    },
  },
}

</script>

<style scoped>

</style>
