<template>
  <div>
    <b-row>
      <b-col cols="4">
        <h6 class="text-primary">
          Número de Poste
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.numeroPoste"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <h6 class="text-primary">
          Clase de Lámpara
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.modeloLampara.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <h6 class="text-primary">
          Grupo
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.grupo.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <h6 class="text-primary">
          Semana
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="semanaDeCreacion"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <h6 class="text-primary">
          Serie de Lámpara
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.codigo"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="8">
        <h6 class="text-primary">
          Fallas
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="obtenerNombresDeFallas(luminaria)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <h6 class="text-primary">
          Tipo Lámpara
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.modelo.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <h6 class="text-primary">
          Potencia
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.potencia.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <h6 class="text-primary">
          Antena
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.antena.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <h6 class="text-primary">
          Nido
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.nido.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <h6 class="text-primary">
          Fecha de Creación
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="formatFecha(luminaria.fechaCreacion)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <h6 class="text-primary">
          Destino de Luminaria
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="destinoLuminariaActual"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <h6 class="text-primary">
          Tipo
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.tipoLampara.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <h6 class="text-primary">
          Estado
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="estadosSeguimiento(luminaria.estado)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="luminaria && luminaria.reparaciones"
        cols="12"
      >
        <h6 class="text-primary">
          Reparaciones realizadas a Luminaria
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="getNombreReparaciones()"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Observaciones de Ingreso
        </h6>
        <b-form-group>
          <b-form-textarea
            rows="2"
            no-resize
            disabled
            :value="luminaria.observaciones"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        sm="12"
      >
        <!-- Modal Pre-Diagnóstico -->
        <b-row v-if="luminaria.estado !== 'DESCARTADA' && luminaria.estado !== 'REPARADA'">
          <hr>
          <b-col
            v-if="luminaria && luminaria.estado !== 'LUMINARIA DESPACHADA'"
            cols="12"
          >
            <div>
              <cambio-estados-luminaria
                :luminaria="luminaria"
                @cerrar-detalle="cerrarDetalle"
              />
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="12"
            sm="12"
          >
            <h6 class="text-primary">
              Tracking Detalle
            </h6>
            <vue-good-table
              :columns="columns"
              :rows="luminaria.seguimiento"
              style-class="vgt-table condensed"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field == 'detalle'">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="gradient-primary"
                    @click="mostrarDetalle(props.row)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </span>
                <span v-else-if="props.column.field == 'fechaHora'">
                  <span>{{ formatFecha(props.row.fechaHora) }}</span>
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
        <b-modal
          v-model="verDialogoDocumentoItem"
          ok-only
          ok-variant="primary"
          ok-title="Aceptar"
          modal-class="modal-primary"
          centered
          size="lg"
          title="Detalle de la Imagen"
        >
          <b-row>
            <b-col
              v-if="seguimientoSeleccionado.estado === 'Despacho de Luminaria'"
              cols="12"
            >
              <b-form-group>
                <h5 class="text-primary">
                  Grupo al que fue despachada
                </h5>
                <b-form-input
                  v-model="luminaria.grupo.nombre"
                  class="text-center"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="seguimientoSeleccionado.estado === 'Pre diagnóstico'"
              cols="12"
            >
              <b-form-group>
                <h5 class="text-primary">
                  Destino de Luminaria
                </h5>
                <b-form-input
                  v-model="seguimientoSeleccionado.destinoLuminaria.nombre"
                  class="text-center"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="seguimientoSeleccionado.estado === 'Pre diagnóstico' && seguimientoSeleccionado.destinoLuminaria.id === '2'"
              cols="12"
            >
              <h6 class="text-primary">
                Lista de Pre diagnósticos
              </h6>
              <b-table
                v-model="fallasSeleccionadasTrue"
                :items="luminaria.preDiagnosticos"
                :fields="camposTabla"
              >
                <template v-slot:cell(nombre)="{ item }">
                  <h5 class="align-text-top text-uppercase">
                    {{ item.nombre }}
                  </h5>
                </template>
                <template v-slot:cell(check)="{ item }">
                  <b-form-checkbox
                    v-model="item.fallasSeleccionadas"
                    disabled
                  />
                </template>
              </b-table>
            </b-col>
            <b-col
              v-if="seguimientoSeleccionado.estado === 'Despacho de Luminaria' && luminaria.seguimiento[1].destinoLuminaria.id === '2'"
              cols="12"
            >
              <h6 class="text-primary">
                Listado de Pruebas de Luminaria
              </h6>
              <b-table
                v-model="pruebasSeleccionadasTrue"
                :items="luminaria.pruebas"
                :fields="camposTabla"
              >
                <template v-slot:cell(nombre)="{ item }">
                  <h5 class="align-text-top text-uppercase">
                    {{ item.nombre }}
                  </h5>
                </template>
                <template v-slot:cell(check)="{ item }">
                  <b-form-checkbox
                    v-model="item.pruebasSeleccionadas"
                    disabled
                  />
                </template>
              </b-table>
            </b-col>
            <b-col
              v-if="seguimientoSeleccionado.estado === 'Luminaria Reparada' && luminaria.seguimiento[1].destinoLuminaria.id === '1'"
              cols="6"
            >
              <center>
                <h6 class="text-primary">
                  Listado de Pruebas de Luminaria
                </h6>
                <b-table
                  v-model="pruebasSeleccionadasTrue"
                  :items="luminaria.pruebas"
                  :fields="camposTabla"
                >
                  <template v-slot:cell(nombre)="{ item }">
                    <h5 class="align-text-top text-uppercase">
                      {{ item.nombre }}
                    </h5>
                  </template>
                  <template v-slot:cell(check)="{ item }">
                    <b-form-checkbox
                      v-model="item.pruebasSeleccionadas"
                      disabled
                    />
                  </template>
                </b-table>
              </center>
            </b-col>
            <b-col
              v-if="seguimientoSeleccionado.estado === 'Luminaria Reparada' && luminaria.seguimiento[1].destinoLuminaria.id === '1'"
              cols="6"
            >
              <b-form-group>
                <center>
                  <h5 class="text-primary">
                    Lista de Reparaciones de Laboratorio
                  </h5>
                  <v-select
                    v-model="luminaria.reparaciones"
                    label="nombre"
                    multiple
                  />
                </center>
              </b-form-group>
            </b-col>
            <b-col
              v-if="seguimientoSeleccionado.estado === 'Luminaria Reparada' && seguimientoSeleccionado.observacionesPruebas !== ''"
              cols="6"
            >
              <b-form-group>
                <center>
                  <h5 class="text-primary">
                    Observaciones de las Pruebas
                  </h5>
                  <b-form-textarea
                    v-model="seguimientoSeleccionado.observacionesPruebas"
                    class="text-center"
                    rows="2"
                    no-resize
                    disabled
                  />
                </center>
              </b-form-group>
            </b-col>
            <b-col
              v-if="seguimientoSeleccionado.estado === 'Luminaria Reparada' && seguimientoSeleccionado.observaciones !== null"
              :cols="luminaria.seguimiento[1].destinoLuminaria.id === '1' ? 6 : 12"
            >
              <b-form-group>
                <center>
                  <h5 class="text-primary">
                    Observaciones de las Reparaciones
                  </h5>
                  <b-form-textarea
                    v-model="seguimientoSeleccionado.observaciones"
                    class="text-center"
                    rows="2"
                    no-resize
                    disabled
                  />
                </center>
              </b-form-group>
            </b-col>
            <b-col
              v-if="seguimientoSeleccionado.estado !== 'Ingreso de Luminaria' && seguimientoSeleccionado.estado !== 'Luminaria Despachada' && seguimientoSeleccionado.estado !== 'Luminaria Reparada'"
              cols="12"
            >
              <b-form-group>
                <h5 class="text-primary">
                  Observaciones
                </h5>
                <b-form-textarea
                  v-model="seguimientoSeleccionado.observaciones"
                  class="text-center"
                  rows="2"
                  no-resize
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="seguimientoSeleccionado.estado !== 'Luminaria Despachada'"
              cols="12"
              sm="12"
            >
              <h6 class="text-primary">
                Tracking de Fotografías
              </h6>
              <vue-good-table
                :columns="columnsDetalle"
                :rows="luminaria.seguimiento ? getImagesFromSeguimiento() : []"
                style-class="vgt-table condensed"
              >
                <div slot="emptystate">
                  <center>
                    No se tomaron fotografías
                  </center>
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field == 'detalleFoto'">
                    <b-button
                      v-if="props.row.url"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="gradient-primary"
                      @click="mostrarImagen(props.row)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </span>
                  <span v-else-if="props.column.field === 'observaciones'">
                    <span>{{ getCommentsFromSeguimiento(props.row) }}</span>
                  </span>
                  <span v-else-if="props.column.field == 'fechaHora'">
                    <span>{{ formatFecha(props.row.fechaHora) }}</span>
                  </span>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          v-model="verDialogoDocumento"
          ok-only
          ok-variant="primary"
          ok-title="Aceptar"
          modal-class="modal-primary"
          centered
          size="lg"
          title="Detalle de la Imagen"
        >
          <div>
            <center>
              <b-img
                :src="urlImagen"
                fluid
                alt="Imagen Cargada')"
              />
            </center>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormInput, BFormGroup, BModal, BButton, VBModal, BImg, BFormTextarea, BFormCheckbox, BTable,
} from 'bootstrap-vue'
import { calcularFecha } from '@/utils/fechas'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import CambioEstadosLuminaria from '@/components/mantenimiento/bodega/cambio-estados-luminaria.vue'
import { calcularSemanaDeCreacion, getListadoFallasLuminaria } from '@/utils/mantenimiento/bodega/bodegaUtils'
import { getlistado } from '@/utils/catalogos'
import vSelect from 'vue-select'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    calcularSemanaDeCreacion,
    CambioEstadosLuminaria,
    BFormTextarea,
    BImg,
    BCol,
    BRow,
    BTable,
    vSelect,
    BFormInput,
    BFormGroup,
    VueGoodTable,
    BModal,
    BButton,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    luminaria: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      urlImagen: '',
      seguimientoSeleccionado: '',
      verDialogoDocumento: false,
      verDialogoDocumentoItem: false,
      columns: [
        {
          label: 'Estado',
          field: 'estado',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Observaciones',
          field: 'observaciones',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      columnsDetalle: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalleFoto',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      fallasSeleccionadas: [],
      pruebasSeleccionadas: [],
      fallas: [],
      camposTabla: [
        { key: 'nombre', label: 'Nombre' },
        { key: 'check', label: '' },
      ],
      itemsPerPage: 10,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    fallasSeleccionadasTrue: {
      get() {
        return this.fallas.filter(falla => falla.fallasSeleccionadas)
      },
      // eslint-disable-next-line no-unused-vars
      set(value) {
      },
    },
    pruebasSeleccionadasTrue: {
      get() {
        return this.luminaria.pruebas.filter(item => item.pruebasSeleccionadas)
      },
      // eslint-disable-next-line no-unused-vars
      set(value) {
      },
    },
    semanaDeCreacion() {
      return calcularSemanaDeCreacion(this.luminaria.fechaCreacion)
    },
    destinoLuminariaActual() {
      if (this.luminaria && this.luminaria.seguimiento) {
        const objetoEncontrado = this.luminaria.seguimiento.find(item => item.destinoLuminaria)

        if (objetoEncontrado) {
          return objetoEncontrado.destinoLuminaria.nombre
        }
      }

      return 'No definido'
    },
  },
  async created() {
    this.estados = getListadoFallasLuminaria(6)
    this.fallas = await getlistado(22, 'Pruebas Lámparas Bodega', false, this.usuario)
    this.pruebas = await getlistado(23, 'Pruebas Lámparas Bodega', false, this.usuario)
  },
  methods: {
    estadosSeguimiento(estado) {
      return this.estados[estado]?.nombre || estado
    },
    getImagesFromSeguimiento() {
      if (this.objetoDeSeguimientoActual && this.objetoDeSeguimientoActual.tracking) {
        return this.objetoDeSeguimientoActual.tracking
      }
      return []
    },
    getCommentsFromSeguimiento() {
      if (this.objetoDeSeguimientoActual && this.objetoDeSeguimientoActual.tracking) {
        return this.objetoDeSeguimientoActual.observaciones
      }
      return []
    },
    getNombreReparaciones() {
      if (this.luminaria && this.luminaria.reparaciones) {
        return this.luminaria.reparaciones.map(reparaciones => reparaciones.nombre).join(', ')
      }
      return ''
    },
    mostrarImagen(file) {
      this.urlImagen = file.url
      this.verDialogoDocumento = true
    },
    mostrarDetalle(objetoSeguimiento) {
      this.objetoDeSeguimientoActual = objetoSeguimiento
      this.seguimientoSeleccionado = objetoSeguimiento
      this.verDialogoDocumentoItem = true
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
    cerrarDetalle() {
      this.$emit('cerrar-detalle')
    },
    obtenerNombresDeFallas(row) {
      if (Array.isArray(row.fallas)) {
        const nombresDeFallas = row.fallas.map(falla => falla.nombre)

        return nombresDeFallas.join(', ')
      }

      return 'No hay datos de fallas'
    },
  },
}
</script>
<style scoped>
.v-select {
  pointer-events: none;
  background-color: #f0f0f0;
  color: #999;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
