// eslint-disable-next-line import/prefer-default-export
export function imprimirEtiquetaZebra(serie, logoApvn, qrEtiqueta) {
  const ventanaImpresion = window.open('', '', 'width=800,height=600')
  ventanaImpresion.document.write(`
    <html lang="es">
      <head>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap" rel="stylesheet">
        <title>Impresión</title>
        <style>
          @page {
            margin: 0mm;
            size: auto;
          }
          body {
            display: flex;
            margin-top: -5px;
            justify-content: space-between;
            align-items: center;
            height: 100vh;
            font-family: 'Oswald', sans-serif;
            font-weight: 500;
            font-size: 14px;
          }
          .qrEtiqueta {
            margin-top: -80px;
            width: 50%;
            text-align: center;
          }
          .serie {
            margin-top: -75px;
            width: 50%;
            text-align: center;
          }
          .logoApvn {
            text-align: center;
          }
        </style>
      </head>
      <body>
        <div class="qrEtiqueta">
          <img style="margin-left: 30px;" src="${qrEtiqueta}" alt="QR Code" width="70px" height="70px">
        </div>
        <div class="serie">
          <p style="margin-top: -5px; margin-left: -40%;">${serie}</p>
          <br>
          <br>
          <h5 style="margin-top: -60px; margin-left: -40%;">PROPIEDAD DE:</h5>
          <img style="margin-top: -22px; margin-left: -40%;" src="${logoApvn}" alt="Logo" width="80px" height="50px">
        </div>
      </body>
    </html>
  `)

  ventanaImpresion.document.close()

  // Habilitar impresión directa (silent printing)
  ventanaImpresion.print()

  // Cerrar la ventana después de un tiempo (ajusta el tiempo según sea necesario)
  setTimeout(() => {
    ventanaImpresion.close()
  }, 1000) // Cierra la ventana después de 1 segundo
}
